<template>
  <ik-data-table
    :entity-name="$t('operationRecords')"
    :model="model"
    :use-action="false"
    :use-default-action="false"
    :use-select="false"
    icon="mdi-developer-board"
  />
</template>

<script>

  import OperationList from '../../../model/restaurant/OperationList'
  import { IkDataTable } from 'metaflow-js'

  export default {
    components: {
      IkDataTable,
    },
    data: function () {
      return ({
        model: OperationList,
      })
    },
  }
</script>
