import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import i18n from '@/i18n'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: i18n.t('id'),
    tableConfig: {
      align: 'start',
    },
  },
  operateType: {
    displayName: i18n.t('operateType'),
  },
  operateName: {
    displayName: i18n.t('operateName'),
  },
  operateData: {
    displayName: i18n.t('operateData'),
  },
  createdAt: {
    type: IKDataEntity.Types.Date,
    displayName: i18n.t('addTimeStamp'),
  },
}
const config = {
  load: async function (filter) {
    return (await hillo.get('AccessLog.php', {
      op: 'getAllRecords', ...filter,
    })).content
  },
}

export default IKDataEntity.ModelFactory(entity, config)
